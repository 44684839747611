import { getFormProps, useForm } from "@conform-to/react"
import { parseWithZod } from "@conform-to/zod"
import { invariantResponse } from "@epic-web/invariant"
import { json, type ActionFunctionArgs } from "@remix-run/node"
import { redirect, useFetcher, useFetchers } from "@remix-run/react"
import { ServerOnly } from "remix-utils/server-only"
import { z } from "zod"

import { setTheme, type Theme } from "@sagaware/services/remix-theme.server"
import { Icon } from "@sagaware/ui-icons/Icon"

import { useHints } from "~/utils/client-hints"
import { useRequestInfo } from "~/utils/request-info"

const ThemeSchema = z.object({
	theme: z.enum(["system", "light", "dark"]),
	// this is useful for progressive enhancement
	redirectTo: z.string().optional(),
})

export async function action({ request }: ActionFunctionArgs) {
	const formData = await request.formData()
	const submission = parseWithZod(formData, {
		schema: ThemeSchema,
	})

	invariantResponse(submission.status === "success", "Invalid theme received")

	const { theme, redirectTo } = submission.value

	const responseInit = {
		headers: { "set-cookie": setTheme(theme) },
	}
	if (redirectTo) {
		return redirect(redirectTo, responseInit)
	}
	return json({ result: submission.reply() }, responseInit)
}

export function ThemeSwitch({
	userPreference,
}: {
	userPreference?: Theme | null
}) {
	const fetcher = useFetcher<typeof action>()
	const requestInfo = useRequestInfo()

	const [form] = useForm({
		id: "theme-switch",
		lastResult: fetcher.data?.result,
	})

	const optimisticMode = useOptimisticThemeMode()
	const mode = optimisticMode ?? userPreference ?? "system"
	const nextMode =
		mode === "system" ? "light" : mode === "light" ? "dark" : "system"
	const modeLabel = {
		light: (
			<Icon name="Sun">
				<span className="sr-only">Light</span>
			</Icon>
		),
		dark: (
			<Icon name="Moon">
				<span className="sr-only">Dark</span>
			</Icon>
		),
		system: (
			// <Icon name="laptop">
			<Icon name="Settings">
				<span className="sr-only">System settings</span>
			</Icon>
		),
	}

	return (
		<fetcher.Form
			method="POST"
			{...getFormProps(form)}
			action="/resources/theme-switch"
		>
			<ServerOnly>
				{() => (
					<input type="hidden" name="redirectTo" value={requestInfo.path} />
				)}
			</ServerOnly>
			<input type="hidden" name="theme" value={nextMode} />
			<div className="flex gap-2">
				<button
					type="submit"
					className="flex size-8 cursor-pointer items-center justify-center"
				>
					{modeLabel[mode]}
				</button>
			</div>
		</fetcher.Form>
	)
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
	const fetchers = useFetchers()
	const themeFetcher = fetchers.find(
		(f) => f.formAction === "/resources/theme-switch",
	)

	if (themeFetcher?.formData) {
		const submission = parseWithZod(themeFetcher.formData, {
			schema: ThemeSchema,
		})

		if (submission.status === "success") {
			return submission.value.theme
		}
	}
}

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme() {
	const hints = useHints()
	const requestInfo = useRequestInfo()
	const optimisticMode = useOptimisticThemeMode()
	if (optimisticMode) {
		return optimisticMode === "system" ? hints.theme : optimisticMode
	}
	return requestInfo.userPrefs.theme ?? hints.theme
}
